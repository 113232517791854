<template>
  <div class="StatSession">
    <StatSessionChart v-if="filters.groupByTime !== 'all'"></StatSessionChart>

    <Card>
      <StatSessionFilters
        :tableStatisticsColumns="tableStatisticsColumnsLength"
        v-model:filters="filters"
        @customize-table="showCustomizeTable()"
      ></StatSessionFilters>

      <Loading v-if="is_loading"></Loading>

      <div v-if="!is_loading" class="StatSession__table">
        <div class="table__wrapper">
          <table class="table table-hoverable table-clickable">
            <thead>
              <tr>
                <th
                  v-if="
                    filters.groupByTime !== 'all' &&
                      tableStatisticsColumns.timestamp
                  "
                >
                  <SortingIcons
                    :label="$lng.__('lk_webmaster_main', 'Date')"
                    :isActive="sortBy === 'Date'"
                    @sorted="setSortingByDate"
                  ></SortingIcons>
                </th>
                <th
                  v-if="
                    (filters.groupBy === 'offer' &&
                      filters.groupBy !== 'shopwindow' &&
                      tableStatisticsColumns.offer == true) ||
                      (filters.groupBy === 'stream' &&
                        filters.groupBy !== 'shopwindow' &&
                        tableStatisticsColumns.stream_id == true) ||
                      (filters.groupBy === 'country' &&
                        filters.groupBy !== 'shopwindow' &&
                        tableStatisticsColumns.country == true) ||
                      (filters.groupBy === 'utm_medium' &&
                        filters.groupBy !== 'shopwindow' &&
                        tableStatisticsColumns.utm_medium == true) ||
                      (filters.groupBy === 'utm_content' &&
                        filters.groupBy !== 'shopwindow' &&
                        tableStatisticsColumns.utm_content == true) ||
                      (filters.groupBy === 'utm_term' &&
                        filters.groupBy !== 'shopwindow' &&
                        tableStatisticsColumns.utm_term == true) ||
                      (filters.groupBy === 'utm_campaign' &&
                        filters.groupBy !== 'shopwindow' &&
                        tableStatisticsColumns.utm_campaign == true)
                  "
                >
                  {{
                    $lng.__(
                      'lk_webmaster_main',
                      filters.groupBy.charAt(0).toUpperCase() +
                        filters.groupBy.slice(1)
                    )
                  }}
                </th>
                <th
                  v-if="
                    (filters.groupBy2 === 'offer' &&
                      filters.groupBy2 !== 'shopwindow' &&
                      tableStatisticsColumns.offer == true) ||
                      (filters.groupBy2 === 'stream' &&
                        filters.groupBy2 !== 'shopwindow' &&
                        tableStatisticsColumns.stream_id == true) ||
                      (filters.groupBy2 === 'country' &&
                        filters.groupBy2 !== 'shopwindow' &&
                        tableStatisticsColumns.country == true) ||
                      (filters.groupBy2 === 'utm_medium' &&
                        filters.groupBy2 !== 'shopwindow' &&
                        tableStatisticsColumns.utm_medium == true) ||
                      (filters.groupBy2 === 'utm_content' &&
                        filters.groupBy2 !== 'shopwindow' &&
                        tableStatisticsColumns.utm_content == true) ||
                      (filters.groupBy2 === 'utm_term' &&
                        filters.groupBy2 !== 'shopwindow' &&
                        tableStatisticsColumns.utm_term == true) ||
                      (filters.groupBy2 === 'utm_campaign' &&
                        filters.groupBy2 !== 'shopwindow' &&
                        tableStatisticsColumns.utm_campaign == true)
                  "
                >
                  {{
                    $lng.__(
                      'lk_webmaster_main',
                      filters.groupBy2.charAt(0).toUpperCase() +
                        filters.groupBy2.slice(1)
                    )
                  }}
                </th>
                <th
                  v-if="
                    (filters.groupBy3 === 'offer' &&
                      filters.groupBy3 !== 'shopwindow' &&
                      tableStatisticsColumns.offer == true) ||
                      (filters.groupBy3 === 'stream' &&
                        filters.groupBy3 !== 'shopwindow' &&
                        tableStatisticsColumns.stream_id == true) ||
                      (filters.groupBy3 === 'country' &&
                        filters.groupBy3 !== 'shopwindow' &&
                        tableStatisticsColumns.country == true) ||
                      (filters.groupBy3 === 'utm_medium' &&
                        filters.groupBy3 !== 'shopwindow' &&
                        tableStatisticsColumns.utm_medium == true) ||
                      (filters.groupBy3 === 'utm_content' &&
                        filters.groupBy3 !== 'shopwindow' &&
                        tableStatisticsColumns.utm_content == true) ||
                      (filters.groupBy3 === 'utm_term' &&
                        filters.groupBy3 !== 'shopwindow' &&
                        tableStatisticsColumns.utm_term == true) ||
                      (filters.groupBy3 === 'utm_campaign' &&
                        filters.groupBy3 !== 'shopwindow' &&
                        tableStatisticsColumns.utm_campaign == true)
                  "
                >
                  {{
                    $lng.__(
                      'lk_webmaster_main',
                      filters.groupBy3.charAt(0).toUpperCase() +
                        filters.groupBy3.slice(1)
                    )
                  }}
                </th>
                <th
                  v-if="
                    (filters.groupBy4 === 'offer' &&
                      filters.groupBy4 !== 'shopwindow' &&
                      tableStatisticsColumns.offer == true) ||
                      (filters.groupBy4 === 'stream' &&
                        filters.groupBy4 !== 'shopwindow' &&
                        tableStatisticsColumns.stream_id == true) ||
                      (filters.groupBy4 === 'country' &&
                        filters.groupBy4 !== 'shopwindow' &&
                        tableStatisticsColumns.country == true) ||
                      (filters.groupBy4 === 'utm_medium' &&
                        filters.groupBy4 !== 'shopwindow' &&
                        tableStatisticsColumns.utm_medium == true) ||
                      (filters.groupBy4 === 'utm_content' &&
                        filters.groupBy4 !== 'shopwindow' &&
                        tableStatisticsColumns.utm_content == true) ||
                      (filters.groupBy4 === 'utm_term' &&
                        filters.groupBy4 !== 'shopwindow' &&
                        tableStatisticsColumns.utm_term == true) ||
                      (filters.groupBy4 === 'utm_campaign' &&
                        filters.groupBy4 !== 'shopwindow' &&
                        tableStatisticsColumns.utm_campaign == true)
                  "
                >
                  {{
                    $lng.__(
                      'lk_webmaster_main',
                      filters.groupBy4.charAt(0).toUpperCase() +
                        filters.groupBy4.slice(1)
                    )
                  }}
                </th>
                <!-- <th v-if="isShowColumnCountry" class="text-nowrap">
                  {{ $lng.__('lk_webmaster_main', 'Country') }}
                </th>
                <th v-if="isShowColumnOffer" class="text-nowrap ">
                  {{ $lng.__('lk_webmaster_main', 'Offer') }}
                </th> -->
                <th
                  class="text-nowrap"
                  v-if="
                    (filters.groupBy === 'shopwindow' ||
                      filters.groupBy2 === 'shopwindow' ||
                      filters.groupBy3 === 'shopwindow' ||
                      filters.groupBy4 === 'shopwindow') &&
                      tableStatisticsColumns.shopwindow_hits
                  "
                >
                  {{ $lng.__('lk_webmaster_main', 'Showcase type') }}
                </th>
                <th
                  class="text-nowrap "
                  v-if="
                    (filters.groupBy === 'shopwindow' ||
                      filters.groupBy2 === 'shopwindow' ||
                      filters.groupBy3 === 'shopwindow' ||
                      filters.groupBy4 === 'shopwindow') &&
                      tableStatisticsColumns.shopwindow_hits
                  "
                >
                  {{ $lng.__('lk_webmaster_main', 'Showcase hits') }}
                </th>
                <th v-if="tableStatisticsColumns.hits">
                  <SortingIcons
                    :label="$lng.__('lk_webmaster_main', 'Hits')"
                    :isActive="sortBy === 'Hits'"
                    @sorted="setSortingByHits"
                  ></SortingIcons>
                </th>
                <th v-if="tableStatisticsColumns.visitors">
                  <SortingIcons
                    :label="$lng.__('lk_webmaster_main', 'Visitors')"
                    :isActive="sortBy === 'Visitors'"
                    @sorted="setSortingByVisitors"
                  ></SortingIcons>
                </th>
                <th v-if="tableStatisticsColumns.leads">
                  <SortingIcons
                    :label="$lng.__('lk_webmaster_main', 'Leads')"
                    :isActive="sortBy === 'Leads'"
                    @sorted="setSortingByLeads"
                  ></SortingIcons>
                </th>
                <th v-if="tableStatisticsColumns.valid">
                  <SortingIcons
                    :label="$lng.__('lk_webmaster_main', 'Valid')"
                    :isActive="sortBy === 'Valid'"
                    @sorted="setSortingByValid"
                  ></SortingIcons>
                </th>
                <th v-if="tableStatisticsColumns.new">
                  <SortingIcons
                    :label="$lng.__('lk_webmaster_main', 'In work')"
                    :isActive="sortBy === 'New'"
                    @sorted="setSortingByNew"
                  ></SortingIcons>
                </th>
                <th v-if="tableStatisticsColumns.no_answer">
                  <SortingIcons
                    :label="$lng.__('lk_webmaster_main', 'No answer')"
                    :isActive="sortBy === 'NoAnswer'"
                    @sorted="setSortingByNoAnswer"
                  ></SortingIcons>
                </th>
                <th v-if="tableStatisticsColumns.confirmed">
                  <SortingIcons
                    :label="$lng.__('lk_webmaster_main', 'Confirmed')"
                    :isActive="sortBy === 'Confirmed'"
                    @sorted="setSortingByConfirmed"
                  ></SortingIcons>
                </th>
                <th v-if="tableStatisticsColumns.rejected">
                  <SortingIcons
                    :label="$lng.__('lk_webmaster_main', 'Rejected')"
                    :isActive="sortBy === 'Rejected'"
                    @sorted="setSortingByRejected"
                  ></SortingIcons>
                </th>
                <th v-if="tableStatisticsColumns.auto_trash">
                  <SortingIcons
                    :label="$lng.__('lk_webmaster_main', 'Trash (auto)')"
                    :isActive="sortBy === 'TrashAuto'"
                    @sorted="setSortingByTrashAuto"
                  ></SortingIcons>
                </th>
                <th v-if="tableStatisticsColumns.trash_cc">
                  <SortingIcons
                    :label="$lng.__('lk_webmaster_main', 'Trash (callcenter)')"
                    :isActive="sortBy === 'TrashCallCenter'"
                    @sorted="setSortingByTrashCallCenter"
                  ></SortingIcons>
                </th>

                <!-- <th
                                    v-if="(filters.groupBy!=='utm_medium' || filters.groupBy2!=='utm_medium' || filters.groupBy3!=='utm_medium') && tableStatisticsColumns.utm_medium"
                                    class="text-nowrap">
                                    {{ $lng.__('lk_webmaster_offers', 'utm_medium') }}
                                </th>
                                <th
                                    v-if="(filters.groupBy!=='utm_content' || filters.groupBy2!=='utm_content' || filters.groupBy3!=='utm_content') && tableStatisticsColumns.utm_content"
                                    class="text-nowrap">
                                    {{ $lng.__('lk_webmaster_offers', 'utm_content') }}
                                </th>
                                <th
                                    v-if="(filters.groupBy!=='utm_term' || filters.groupBy2!=='utm_term' || filters.groupBy3!=='utm_term') && tableStatisticsColumns.utm_term"
                                    class="text-nowrap">
                                    {{ $lng.__('lk_webmaster_offers', 'utm_term') }}
                                </th>
                                <th
                                    v-if="(filters.groupBy!=='utm_campaign' || filters.groupBy2!=='utm_campaign' || filters.groupBy3!='utm_campaign') && tableStatisticsColumns.utm_campaign"
                                    class="text-nowrap">
                                    {{ $lng.__('lk_webmaster_offers', 'utm_campaign') }}
                                </th>-->

                <th v-if="tableStatisticsColumns.cr">CR</th>
                <th v-if="tableStatisticsColumns.cpc">eCPC</th>
                <!-- <th>
                                    <SortingIcons
                                        :label="$lng.__('lk_webmaster_main', 'Money Confirmed')"
                                        :isActive="sortBy==='Money'"
                                        @sorted="setSortingByMoney"
                                    ></SortingIcons>
                                </th> -->
                <th
                  class="text-nowrap"
                  v-if="tableStatisticsColumns.money_confirmed_eur"
                >
                  {{ $lng.__('lk_webmaster_main', 'Money Confirmed EUR') }}
                </th>
                <th
                  class="text-nowrap"
                  v-if="tableStatisticsColumns.money_confirmed_usd"
                >
                  {{ $lng.__('lk_webmaster_main', 'Money Confirmed USD') }}
                </th>
                <!-- <th v-if="filters.groupBy!=='stream' && tableStatisticsColumns.stream_id">
                                    {{ $lng.__('lk_webmaster_main', 'Stream') }}
                                </th> -->
                <th
                  class="text-nowrap"
                  v-if="tableStatisticsColumns.approve_from_valid_leads"
                >
                  {{
                    $lng.__('lk_webmaster_main', '% approve from valid leads')
                  }}
                </th>
                <th
                  class="text-nowrap"
                  v-if="tableStatisticsColumns.approve_from_all_leads"
                >
                  {{ $lng.__('lk_webmaster_main', '% approve from all leads') }}
                </th>
                <th
                  class="text-nowrap"
                  v-if="tableStatisticsColumns.indication_by_customer_check"
                >
                  {{
                    $lng.__('lk_webmaster_main', 'Indication by customer check')
                  }}
                </th>
                <th
                  class="text-nowrap"
                  v-if="
                    tableStatisticsColumns.indication_of_order_buyout_by_leads
                  "
                >
                  {{
                    $lng.__(
                      'lk_webmaster_main',
                      'Indication of order buyout by leads'
                    )
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(session, index) in sessionsSorted" :key="index">
                <tr
                  v-if="!isEmptyOtherDataSession(session)"
                  @click="openInStatLead(session)"
                >
                  <td
                    v-if="
                      filters.groupByTime !== 'all' &&
                        tableStatisticsColumns.timestamp
                    "
                    class="text-nowrap text-left"
                  >
                    {{ session.timestamp }}
                  </td>
                  <td
                    v-if="
                      (filters.groupBy === 'offer' &&
                        filters.groupBy !== 'shopwindow' &&
                        tableStatisticsColumns.offer == true) ||
                        (filters.groupBy === 'stream' &&
                          filters.groupBy !== 'shopwindow' &&
                          tableStatisticsColumns.stream_id == true) ||
                        (filters.groupBy === 'country' &&
                          filters.groupBy !== 'shopwindow' &&
                          tableStatisticsColumns.country == true) ||
                        (filters.groupBy === 'utm_medium' &&
                          filters.groupBy !== 'shopwindow' &&
                          tableStatisticsColumns.utm_medium == true) ||
                        (filters.groupBy === 'utm_content' &&
                          filters.groupBy !== 'shopwindow' &&
                          tableStatisticsColumns.utm_content == true) ||
                        (filters.groupBy === 'utm_term' &&
                          filters.groupBy !== 'shopwindow' &&
                          tableStatisticsColumns.utm_term == true) ||
                        (filters.groupBy === 'utm_campaign' &&
                          filters.groupBy !== 'shopwindow' &&
                          tableStatisticsColumns.utm_campaign == true)
                    "
                    class="text-nowrap"
                  >
                    <template
                      v-if="
                        filters.groupBy === 'offer' &&
                          tableStatisticsColumns.offer
                      "
                      >{{ session.offer.name }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy === 'stream' &&
                          tableStatisticsColumns.stream_id
                      "
                      >{{ getStreamNameById(session.stream_id) }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy === 'country' &&
                          tableStatisticsColumns.country
                      "
                      >{{
                        session.country ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy === 'utm_medium' &&
                          tableStatisticsColumns.utm_medium
                      "
                      >{{
                        session.utm_medium ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy === 'utm_content' &&
                          tableStatisticsColumns.utm_content
                      "
                      >{{
                        session.utm_content ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy === 'utm_term' &&
                          tableStatisticsColumns.utm_term
                      "
                      >{{
                        session.utm_term ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy === 'utm_campaign' &&
                          tableStatisticsColumns.utm_campaign
                      "
                      >{{
                        session.utm_campaign ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                  </td>
                  <td
                    v-if="
                      (filters.groupBy2 === 'offer' &&
                        filters.groupBy2 !== 'shopwindow' &&
                        tableStatisticsColumns.offer == true) ||
                        (filters.groupBy2 === 'stream' &&
                          filters.groupBy2 !== 'shopwindow' &&
                          tableStatisticsColumns.stream_id == true) ||
                        (filters.groupBy2 === 'country' &&
                          filters.groupBy2 !== 'shopwindow' &&
                          tableStatisticsColumns.country == true) ||
                        (filters.groupBy2 === 'utm_medium' &&
                          filters.groupBy2 !== 'shopwindow' &&
                          tableStatisticsColumns.utm_medium == true) ||
                        (filters.groupBy2 === 'utm_content' &&
                          filters.groupBy2 !== 'shopwindow' &&
                          tableStatisticsColumns.utm_content == true) ||
                        (filters.groupBy2 === 'utm_term' &&
                          filters.groupBy2 !== 'shopwindow' &&
                          tableStatisticsColumns.utm_term == true) ||
                        (filters.groupBy2 === 'utm_campaign' &&
                          filters.groupBy2 !== 'shopwindow' &&
                          tableStatisticsColumns.utm_campaign == true)
                    "
                    class="text-nowrap"
                  >
                    <template
                      v-if="
                        filters.groupBy2 === 'offer' &&
                          tableStatisticsColumns.offer
                      "
                      >{{ session.offer.name }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy2 === 'stream' &&
                          tableStatisticsColumns.stream_id
                      "
                      >{{ getStreamNameById(session.stream_id) }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy2 === 'country' &&
                          tableStatisticsColumns.country
                      "
                      >{{
                        session.country ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy2 === 'utm_medium' &&
                          tableStatisticsColumns.utm_medium
                      "
                      >{{
                        session.utm_medium ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy2 === 'utm_content' &&
                          tableStatisticsColumns.utm_content
                      "
                      >{{
                        session.utm_content ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy2 === 'utm_term' &&
                          tableStatisticsColumns.utm_term
                      "
                      >{{
                        session.utm_term ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy2 === 'utm_campaign' &&
                          tableStatisticsColumns.offer
                      "
                      >{{
                        session.utm_campaign ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                  </td>
                  <td
                    v-if="
                      (filters.groupBy3 === 'offer' &&
                        filters.groupBy3 !== 'shopwindow' &&
                        tableStatisticsColumns.offer == true) ||
                        (filters.groupBy3 === 'stream' &&
                          filters.groupBy3 !== 'shopwindow' &&
                          tableStatisticsColumns.stream_id == true) ||
                        (filters.groupBy3 === 'country' &&
                          filters.groupBy3 !== 'shopwindow' &&
                          tableStatisticsColumns.country == true) ||
                        (filters.groupBy3 === 'utm_medium' &&
                          filters.groupBy3 !== 'shopwindow' &&
                          tableStatisticsColumns.utm_medium == true) ||
                        (filters.groupBy3 === 'utm_content' &&
                          filters.groupBy3 !== 'shopwindow' &&
                          tableStatisticsColumns.utm_content == true) ||
                        (filters.groupBy3 === 'utm_term' &&
                          filters.groupBy3 !== 'shopwindow' &&
                          tableStatisticsColumns.utm_term == true) ||
                        (filters.groupBy3 === 'utm_campaign' &&
                          filters.groupBy3 !== 'shopwindow' &&
                          tableStatisticsColumns.utm_campaign == true)
                    "
                    class="text-nowrap"
                  >
                    <template
                      v-if="
                        filters.groupBy3 === 'offer' &&
                          tableStatisticsColumns.offer
                      "
                      >{{ session.offer.name }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy3 === 'stream' &&
                          tableStatisticsColumns.stream_id
                      "
                      >{{ getStreamNameById(session.stream_id) }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy3 === 'country' &&
                          tableStatisticsColumns.country
                      "
                      >{{
                        session.country ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy3 === 'utm_medium' &&
                          tableStatisticsColumns.utm_medium
                      "
                      >{{
                        session.utm_medium ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy3 === 'utm_content' &&
                          tableStatisticsColumns.utm_content
                      "
                      >{{
                        session.utm_content ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy3 === 'utm_term' &&
                          tableStatisticsColumns.utm_term
                      "
                      >{{
                        session.utm_term ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy3 === 'utm_campaign' &&
                          tableStatisticsColumns.utm_campaign
                      "
                      >{{
                        session.utm_campaign ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                  </td>
                  <td
                    v-if="
                      (filters.groupBy4 === 'offer' &&
                        filters.groupBy4 !== 'shopwindow' &&
                        tableStatisticsColumns.offer == true) ||
                        (filters.groupBy4 === 'stream' &&
                          filters.groupBy4 !== 'shopwindow' &&
                          tableStatisticsColumns.stream_id == true) ||
                        (filters.groupBy4 === 'country' &&
                          filters.groupBy4 !== 'shopwindow' &&
                          tableStatisticsColumns.country == true) ||
                        (filters.groupBy4 === 'utm_medium' &&
                          filters.groupBy4 !== 'shopwindow' &&
                          tableStatisticsColumns.utm_medium == true) ||
                        (filters.groupBy4 === 'utm_content' &&
                          filters.groupBy4 !== 'shopwindow' &&
                          tableStatisticsColumns.utm_content == true) ||
                        (filters.groupBy4 === 'utm_term' &&
                          filters.groupBy4 !== 'shopwindow' &&
                          tableStatisticsColumns.utm_term == true) ||
                        (filters.groupBy4 === 'utm_campaign' &&
                          filters.groupBy4 !== 'shopwindow' &&
                          tableStatisticsColumns.utm_campaign == true)
                    "
                    class="text-nowrap"
                  >
                    <template
                      v-if="
                        filters.groupBy4 === 'offer' &&
                          tableStatisticsColumns.offer
                      "
                      >{{ session.offer.name }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy4 === 'stream' &&
                          tableStatisticsColumns.stream_id
                      "
                      >{{ getStreamNameById(session.stream_id) }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy4 === 'country' &&
                          tableStatisticsColumns.country
                      "
                      >{{
                        session.country ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy4 === 'utm_medium' &&
                          tableStatisticsColumns.utm_medium
                      "
                      >{{
                        session.utm_medium ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy4 === 'utm_content' &&
                          tableStatisticsColumns.utm_content
                      "
                      >{{
                        session.utm_content ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy4 === 'utm_term' &&
                          tableStatisticsColumns.utm_term
                      "
                      >{{
                        session.utm_term ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                    <template
                      v-if="
                        filters.groupBy4 === 'utm_campaign' &&
                          tableStatisticsColumns.utm_campaign
                      "
                      >{{
                        session.utm_campaign ||
                          $lng.__(
                            'lk_webmaster_main',
                            'Not enough data to show this report'
                          )
                      }}</template
                    >
                  </td>
                  <!-- <td v-if="isShowColumnCountry">
                    {{
                      session.country ||
                        $lng.__(
                          'lk_webmaster_main',
                          'Not enough data to show this report'
                        )
                    }}
                  </td>
                  <td v-if="isShowColumnOffer">
                    {{
                      session.offer.name ||
                        $lng.__(
                          'lk_webmaster_main',
                          'Not enough data to show this report'
                        )
                    }}
                  </td> -->
                  <td
                    v-if="
                      (filters.groupBy === 'shopwindow' ||
                        filters.groupBy2 === 'shopwindow' ||
                        filters.groupBy3 === 'shopwindow' ||
                        filters.groupBy4 === 'shopwindow') &&
                        tableStatisticsColumns.shopwindow_hits
                    "
                  >
                    {{
                      session.shopwindow_type ||
                        $lng.__(
                          'lk_webmaster_main',
                          'Not enough data to show this report'
                        )
                    }}
                  </td>
                  <td v-if="
                      (filters.groupBy === 'shopwindow' ||
                        filters.groupBy2 === 'shopwindow' ||
                        filters.groupBy3 === 'shopwindow' ||
                        filters.groupBy4 === 'shopwindow') &&
                        tableStatisticsColumns.shopwindow_hits
                    ">
                    {{
                      session.shopwindow_hits ||
                        $lng.__(
                          'lk_webmaster_main',
                          'Not enough data to show this report'
                        )
                    }}
                  </td>
                  <td v-if="tableStatisticsColumns.hits">{{ session.hits }}</td>                  
                  <td v-if="tableStatisticsColumns.visitors">
                    {{ session.visitors }}
                  </td>
                  <td v-if="tableStatisticsColumns.leads">
                    {{ session.leads }}
                  </td>
                  <td v-if="tableStatisticsColumns.valid">
                    {{ session.valid }}
                  </td>
                  <td v-if="tableStatisticsColumns.new">{{ session.new }}</td>
                  <td v-if="tableStatisticsColumns.no_answer">
                    {{ session.no_answer }}
                  </td>
                  <td v-if="tableStatisticsColumns.confirmed">
                    {{ session.confirmed }}
                  </td>
                  <td v-if="tableStatisticsColumns.rejected">
                    {{ session.rejected }}
                  </td>
                  <td v-if="tableStatisticsColumns.auto_trash">
                    {{ session.auto_trash }}
                  </td>
                  <td v-if="tableStatisticsColumns.trash_cc">
                    {{ session.trash_cc }}
                  </td>

                  <!-- <td v-if="(filters.groupBy!=='utm_medium' || filters.groupBy2!=='utm_medium' || filters.groupBy3!=='utm_medium') && tableStatisticsColumns.utm_medium">
                                    {{ session.utm_medium || $lng.__('lk_webmaster_main', 'Not enough data to show this report') }}
                                </td>
                                <td v-if="(filters.groupBy!=='utm_content' || filters.groupBy2!=='utm_content' || filters.groupBy3!=='utm_content') && tableStatisticsColumns.utm_content">
                                    {{ session.utm_content || $lng.__('lk_webmaster_main', 'Not enough data to show this report') }}
                                </td>
                                <td v-if="(filters.groupBy!=='utm_term' || filters.groupBy2!=='utm_term' || filters.groupBy3!=='utm_term') && tableStatisticsColumns.utm_term">
                                    {{ session.utm_term || $lng.__('lk_webmaster_main', 'Not enough data to show this report') }}
                                </td>
                                <td v-if="(filters.groupBy!=='utm_campaign' || filters.groupBy2!='utm_campaign' || filters.groupBy3!=='utm_campaign') && tableStatisticsColumns.utm_campaign">
                                    {{ session.utm_campaign || $lng.__('lk_webmaster_main', 'Not enough data to show this report') }}
                                </td> -->

                  <td v-if="tableStatisticsColumns.cr">{{ session.cr }}</td>
                  <td v-if="tableStatisticsColumns.cpc">{{ session.cpc }}</td>

                  <!-- <td>{{ formatMoneyConfirmed(session.money_confirmed.amount) }} {{session.money_confirmed.currency}}</td> -->
                  <td v-if="tableStatisticsColumns.money_confirmed_eur">
                    {{
                      formatMoneyConfirmed(session.money_confirmed_eur.amount)
                    }}
                    {{ session.money_confirmed_eur.currency }}
                  </td>
                  <td v-if="tableStatisticsColumns.money_confirmed_usd">
                    {{
                      formatMoneyConfirmed(session.money_confirmed_usd.amount)
                    }}
                    {{ session.money_confirmed_usd.currency }}
                  </td>
                  <!-- <td
                                    class="text-nowrap "
                                    v-if="filters.groupBy!=='stream' && tableStatisticsColumns.stream_id">
                                    {{ getStreamNameById(session.stream_id) }}
                                </td> -->
                  <td v-if="tableStatisticsColumns.approve_from_valid_leads">
                    <template
                      v-if="
                        session.approve_from_valid_leads == undefined ||
                          session.approve_from_valid_leads == null
                      "
                    >
                      {{
                        $lng.__(
                          'lk_webmaster_main',
                          'Not enough data to show this report'
                        )
                      }}
                    </template>
                    <template v-else>
                      {{ `${session.approve_from_valid_leads}%` }}
                    </template>
                  </td>
                  <td v-if="tableStatisticsColumns.approve_from_all_leads">
                    <template
                      v-if="
                        session.approve_from_valid_leads == undefined ||
                          session.approve_from_valid_leads == null
                      "
                    >
                      {{
                        $lng.__(
                          'lk_webmaster_main',
                          'Not enough data to show this report'
                        )
                      }}
                    </template>
                    <template v-else>
                      {{ `${session.approve_from_all_leads}%` }}
                    </template>
                  </td>
                  <td
                    v-if="tableStatisticsColumns.indication_by_customer_check"
                  >
                    <div
                      class="indicator indicator_red"
                      v-if="
                        parseFloat(
                          session.indication_by_customer_check.amount
                        ) > 0 &&
                          parseFloat(
                            session.indication_by_customer_check.amount
                          ) <= 90
                      "
                    >
                      {{ $lng.__('lk_webmaster_main', 'Bad result') }}
                    </div>
                    <div
                      class="indicator indicator_yellow"
                      v-else-if="
                        parseFloat(
                          session.indication_by_customer_check.amount
                        ) > 90 &&
                          parseFloat(
                            session.indication_by_customer_check.amount
                          ) < 125
                      "
                    >
                      {{ $lng.__('lk_webmaster_main', 'Average result') }}
                    </div>
                    <div
                      class="indicator indicator_green"
                      v-else-if="
                        parseFloat(
                          session.indication_by_customer_check.amount
                        ) >= 125
                      "
                    >
                      {{ $lng.__('lk_webmaster_main', 'Good result') }}
                    </div>
                    <div class="indicator" v-else>
                      {{
                        $lng.__(
                          'lk_webmaster_main',
                          'Not enough data to show this report'
                        )
                      }}
                    </div>
                  </td>
                  <td
                    v-if="
                      tableStatisticsColumns.indication_of_order_buyout_by_leads
                    "
                  >
                    <div
                      class="indicator indicator_red"
                      v-if="
                        (parseFloat(
                          session.indication_of_order_buyout_by_leads.buyout
                        ) < 59 &&
                          parseFloat(
                            session.indication_of_order_buyout_by_leads.refund
                          ) > 19) ||
                          (parseFloat(
                            session.indication_of_order_buyout_by_leads.buyout
                          ) > 59 &&
                            parseFloat(
                              session.indication_of_order_buyout_by_leads.buyout
                            ) < 70 &&
                            parseFloat(
                              session.indication_of_order_buyout_by_leads.refund
                            ) > 19)
                      "
                    >
                      {{ $lng.__('lk_webmaster_main', 'Bad result') }}
                    </div>
                    <div
                      class="indicator indicator_yellow"
                      v-else-if="
                        (parseFloat(
                          session.indication_of_order_buyout_by_leads.buyout
                        ) > 59 &&
                          parseFloat(
                            session.indication_of_order_buyout_by_leads.buyout
                          ) < 70 &&
                          parseFloat(
                            session.indication_of_order_buyout_by_leads.refund
                          ) < 19) ||
                          (parseFloat(
                            session.indication_of_order_buyout_by_leads.buyout
                          ) > 70 &&
                            parseFloat(
                              session.indication_of_order_buyout_by_leads.buyout
                            ) < 80 &&
                            parseFloat(
                              session.indication_of_order_buyout_by_leads.refund
                            ) > 19)
                      "
                    >
                      {{ $lng.__('lk_webmaster_main', 'Average result') }}
                    </div>
                    <div
                      class="indicator indicator_green"
                      v-else-if="
                        (parseFloat(
                          session.indication_of_order_buyout_by_leads.buyout
                        ) > 70 &&
                          parseFloat(
                            session.indication_of_order_buyout_by_leads.buyout
                          ) < 80 &&
                          parseFloat(
                            session.indication_of_order_buyout_by_leads.refund
                          ) < 19) ||
                          parseFloat(
                            session.indication_of_order_buyout_by_leads.buyout
                          ) > 80
                      "
                    >
                      {{ $lng.__('lk_webmaster_main', 'Good result') }}
                    </div>
                    <div class="indicator" v-else>
                      {{
                        $lng.__(
                          'lk_webmaster_main',
                          'Not enough data to show this report'
                        )
                      }}
                    </div>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </Card>
  </div>

  <!-- MODAL -->
  <Modal
    :title="$lng.__('lk_webmaster_main', 'Customize table')"
    v-if="showModal"
    @close="closeModal"
    addclass="customize-table"
  >
    <div class="customize-table__wrapper">
      <div
        :class="[(key == 'shopwindow_hits' || key == 'country' || key == 'offer' || key == 'stream_id' || key == 'utm_term' || key == 'utm_campaign' || key == 'utm_medium' || key == 'utm_content' || key == 'timestamp') ? 'customize-table__item_hide' : '', 'customize-table__item']"
        v-for="(key, index) in Object.keys(this.tableStatisticsColumns)"
        :key="index"
      >
        <FormSwitch
          :label="$lng.__('lk_webmaster_offers', key)"
          @update:checked="switchColumn(key)"
          :checked="tableStatisticsColumns[`${key}`]"
        ></FormSwitch>
      </div>
    </div>
    <div class="customize-table__button-wrapper">
      <button
        @click="resetCustomizeTableFilters()"
        class="wv-btn--green wv-btn--text wv-btn--block"
      >
        {{ $lng.__('lk_webmaster_main', 'Reset') }}
      </button>
      <button
        @click="saveTableStatisticsColumns()"
        class="wv-btn--green wv-btn--text wv-btn--block"
      >
        {{ $lng.__('lk_webmaster_main', 'Save columns') }}
      </button>
    </div>
  </Modal>
</template>

<script>
  import StatSessionFilters from './StatSessionFilters.vue';
  import StatSessionChart from './StatSessionChart.vue';

  export default {
    name: 'StatSession',
    props: [],
    data() {
      return {
        is_loading: true,
        sortBy: 'Leads',
        sortDirection: 'desc',
        filters: {
          dateRange: {
            startDate: new Date(),
            endDate: new Date(),
          },
          groupBy: 'country',
          groupBy2: false,
          groupBy3: false,
          groupBy4: false,
          groupByTime: 'all',
          utm_content: [],
          utm_term: [],
          utm_medium: [],
          utm_campaign: [],
          stream: [],
          country: [],
          offer: [],
          shopwindow_type: [],
        },
        tableStatisticsColumns: {},
        showModal: false,
      };
    },
    computed: {
      sessions() {
        return this.$store.state.webmaster.sessions;
      },
      sessionsFilters() {
        return this.$store.state.webmaster.sessionsFilters;
      },
      tableStatisticsColumnsLength() {
        return Object.keys(this.tableStatisticsColumns).length;
      },
      // сортировки
      sessionsSorted() {
        let sorted = this.sessions.sort((a, b) => {
          // сортировка по Date
          if (this.sortBy === 'Date') {
            let first = typeof a.timestamp === 'object' ? '' : a.timestamp;
            let last = typeof b.timestamp === 'object' ? '' : b.timestamp;
            return first.localeCompare(last);
          }

          // сортировка по Hits
          if (this.sortBy === 'Hits') {
            return parseInt(a.hits) - parseInt(b.hits);
          }

          // сортировка по Leads
          if (this.sortBy === 'Leads') {
            return parseInt(a.leads) - parseInt(b.leads);
          }

          // сортировка по Visitors
          if (this.sortBy === 'Visitors') {
            return parseInt(a.visitors) - parseInt(b.visitors);
          }

          // сортировка по Valid
          if (this.sortBy === 'Valid') {
            return parseInt(a.valid) - parseInt(b.valid);
          }

          // сортировка по New
          if (this.sortBy === 'New') {
            return parseInt(a.new) - parseInt(b.new);
          }

          // сортировка по NoAnswer
          if (this.sortBy === 'NoAnswer') {
            return parseInt(a.no_answer) - parseInt(b.no_answer);
          }

          // сортировка по Confirmed
          if (this.sortBy === 'Confirmed') {
            return parseInt(a.confirmed) - parseInt(b.confirmed);
          }

          // сортировка по Rejected
          if (this.sortBy === 'Rejected') {
            return parseInt(a.rejected) - parseInt(b.rejected);
          }

          // сортировка по TrashAuto
          if (this.sortBy === 'TrashAuto') {
            return parseInt(a.auto_trash) - parseInt(b.auto_trash);
          }

          // сортировка по TrashCallCenter
          if (this.sortBy === 'TrashCallCenter') {
            return parseInt(a.trash_cc) - parseInt(b.trash_cc);
          }

          // сортировка по Money
          // if (this.sortBy === 'Money') {
          //     return parseFloat(a.money_confirmed.amount) - parseFloat(b.money_confirmed.amount);
          // }
        });

        let needReverse = this.sortDirection === 'desc';

        return needReverse ? sorted.reverse() : sorted;
      },
      serializedFilters() {
        let filters = {
          from:
            this.filters.dateRange.startDate.getFormattedDate() + ' 00:00:00',
          to: this.filters.dateRange.endDate.getFormattedDate() + ' 23:59:59',
          group_by_time: this.filters.groupByTime,
          limit: 5000,
        };

        if (this.filters.utm_campaign.length)
          filters.utm_campaign = this.filters.utm_campaign[0].id;
        if (this.filters.utm_term.length)
          filters.utm_term = this.filters.utm_term[0].id;
        if (this.filters.utm_content.length)
          filters.utm_content = this.filters.utm_content[0].id;
        if (this.filters.utm_medium.length)
          filters.utm_medium = this.filters.utm_medium[0].id;
        if (this.filters.offer.length) {
          filters.offer_id = this.filters.offer[0].id;
        }
        if (this.filters.country.length) {
          filters.country = this.filters.country[0].id;
        }
        if (this.filters.shopwindow_type.length) {
          filters.shopwindow_type = this.filters.shopwindow_type[0].id;
        }

        if (this.filters.groupBy) {
          filters.group_by = this.filters.groupBy;
        }
        if (this.filters.groupBy2) {
          filters.group_by2 = this.filters.groupBy2;
        }
        if (this.filters.groupBy3) {
          filters.group_by3 = this.filters.groupBy3;
        }
        if (this.filters.groupBy4) {
          filters.group_by4 = this.filters.groupBy4;
        }

        if (this.filters.stream.length) {
          filters.stream_id =
            this.filters.stream[0].id === null ? 0 : this.filters.stream[0].id;
        }

        return new URLSearchParams(filters).toString();
      },
      isGroupByShopwindow() {
        return this.filters.groupBy === 'shopwindow';
      },
      isShowColumnCountry() {
        return (
          this.filters.groupBy !== 'country' &&
          this.filters.groupBy2 !== 'country' &&
          this.filters.groupBy3 !== 'country' &&
          this.filters.groupBy4 !== 'country' &&
          this.tableStatisticsColumns.country == true
        );
      },
      isShowColumnOffer() {
        return (
          this.filters.groupBy !== 'offer' &&
          this.filters.groupBy2 !== 'offer' &&
          this.filters.groupBy3 !== 'offer' &&
          this.filters.groupBy4 !== 'offer' &&
          this.tableStatisticsColumns.offer == true
        );
      },
    },
    methods: {
      setSorting(by, direction) {
        this.sortBy = by;
        this.sortDirection = direction;
      },
      setSortingByDate(direction) {
        this.setSorting('Date', direction);
      },
      setSortingByLeads(direction) {
        this.setSorting('Leads', direction);
      },
      setSortingByHits(direction) {
        this.setSorting('Hits', direction);
      },
      setSortingByVisitors(direction) {
        this.setSorting('Visitors', direction);
      },
      setSortingByValid(direction) {
        this.setSorting('Valid', direction);
      },
      setSortingByNew(direction) {
        this.setSorting('New', direction);
      },
      setSortingByNoAnswer(direction) {
        this.setSorting('NoAnswer', direction);
      },
      setSortingByConfirmed(direction) {
        this.setSorting('Confirmed', direction);
      },
      setSortingByRejected(direction) {
        this.setSorting('Rejected', direction);
      },
      setSortingByTrashAuto(direction) {
        this.setSorting('TrashAuto', direction);
      },
      setSortingByTrashCallCenter(direction) {
        this.setSorting('TrashCallCenter', direction);
      },
      // setSortingByMoney(direction) {
      //     this.setSorting('Money', direction);
      // },

      getStatSession() {
        this.is_loading = true;
        this.$store
          .dispatch('webmaster/getStatSession', this.serializedFilters)
          .then(() => {
            if (
              this.sessions.length > 0 &&
              !localStorage.getItem('tableStatisticsColumns')
            ) {
              this.tableStatisticsColumns = this.sessions[0];
              let obj = {};
              for (let [key] of Object.entries(this.sessions[0])) {
                if (
                  key != 'money_confirmed' &&
                  key != 'shopwindow_type' &&
                  key != 'utm_source'
                ) {
                  if(key == 'shopwindow_hits' || key == 'country' || key == 'offer' || key == 'stream_id' || key == 'utm_term' || key == 'utm_campaign' || key == 'utm_medium' || key == 'utm_content'){
                    if (key == this.filters.groupBy || key == this.filters.groupBy2 || key == this.filters.groupBy3 || key == this.filters.groupBy4) {
                      obj[key] = key;
                      obj[key] = true;
                    } else if (key == 'stream_id') {
                      if (key == `${this.filters.groupBy}_id` || key == `${this.filters.groupBy2}_id` || key == `${this.filters.groupBy3}_id` || key == `${this.filters.groupBy4}_id`) {                      
                        obj[key] = key;
                        obj[key] = true;
                      } else {
                        obj[key] = key;
                        obj[key] = false;
                      }
                    } else if (key == 'shopwindow_hits') {
                      if (key == `${this.filters.groupBy}_hits` || key == `${this.filters.groupBy2}_hits` || key == `${this.filters.groupBy3}_hits` || key == `${this.filters.groupBy4}_hits`) {
                        obj[key] = key;
                        obj[key] = true;
                      } else {
                        obj[key] = key;
                        obj[key] = false;
                      }
                    } else {
                      obj[key] = key;
                      obj[key] = false;
                    }
                  } else {
                    obj[key] = key;
                    obj[key] = true;
                  }
                }
              }
              this.tableStatisticsColumns = obj;
            } else {
              this.getTableStatisticsColumns();
            }
            this.is_loading = false;
          });
      },
      getValueForGroupByColumn(group, row) {
        if (group.id === 'offer') {
          return row.offer.name;
        }
      },
      getStreamNameById(stream_id) {
        let stream = collect(this.sessionsFilters.streams)
          .where('id', stream_id)
          .first();

        return stream
          ? stream.name
          : this.$lng.__('lk_webmaster_main', 'No stream');
      },
      formatMoneyConfirmed(value) {
        return typeof value === 'string' ? value.replace('.00', '') : value;
      },
      openInStatLead(session) {
        let query = {};

        // Готовим диапазон дат для статистики по лидам
        let startDate = session.timestamp;
        let endDate = session.timestamp;
        if (this.filters.groupByTime === 'weeks') {
          startDate = session.timestamp.split(' - ')[0];
          endDate = session.timestamp.split(' - ')[1];
        }
        if (this.filters.groupByTime === 'all') {
          startDate = this.filters.dateRange.startDate.getFormattedDate();
          endDate = this.filters.dateRange.endDate.getFormattedDate();
        }
        query.startDate = startDate;
        query.endDate = endDate;

        // Готовим оффер
        if (this.filters.offer.length) {
          query.offer = JSON.stringify(this.filters.offer[0]);
        } else {
          query.offer = JSON.stringify(session.offer);
        }

        // Готовим поток
        if (this.filters.stream.length) {
          query.stream = JSON.stringify(this.filters.stream[0]);
        } else {
          if (session.stream_id) {
            query.stream = JSON.stringify(session.stream_id);
          }
        }

        // Готовим страну
        if (this.filters.country.length) {
          query.country = this.filters.country[0].id;
        } else {
          query.country = session.country;
        }

        // Готовим Utm
        if (this.filters.utm_content.length) {
          query.utm_content = this.filters.utm_content[0].id;
        }
        if (this.filters.utm_medium.length) {
          query.utm_medium = this.filters.utm_medium[0].id;
        }
        if (this.filters.utm_term.length) {
          query.utm_term = this.filters.utm_term[0].id;
        }
        if (this.filters.utm_campaign.length) {
          query.utm_campaign = this.filters.utm_campaign[0].id;
        }

        this.$router.push({
          path: '/' + this.$route.params.lng + '/webmaster/stat/lead',
          query: query,
        });
      },
      showCustomizeTable() {
        this.showModal = !this.showModal;
      },
      closeModal() {
        this.showModal = false;
      },
      switchColumn(key) {
        this.tableStatisticsColumns[`${key}`] = !this.tableStatisticsColumns[
          `${key}`
        ];
      },
      resetCustomizeTableFilters() {
        for (let key in this.tableStatisticsColumns) {
          if (key != 'shopwindow_hits' && key != 'country' && key != 'offer' && key != 'stream_id' && key != 'utm_term' && key != 'utm_campaign' && key != 'utm_medium' && key != 'utm_content') {
            this.tableStatisticsColumns[`${key}`] = true;
          }
        }
        if (localStorage.getItem('tableStatisticsColumns')) {
          localStorage.removeItem('tableStatisticsColumns');
        }
        this.closeModal();
      },
      saveTableStatisticsColumns() {
        let data = Object.entries(this.tableStatisticsColumns).reduce((array, [key, value]) => {
          if (key != 'shopwindow_hits' && key != 'country' && key != 'offer' && key != 'stream_id' && key != 'utm_term' && key != 'utm_campaign' && key != 'utm_medium' && key != 'utm_content') {
            array = { ...array, [key]: value }
          }
          return array;
        }, {});
        localStorage.setItem('tableStatisticsColumns', JSON.stringify(data));
        this.closeModal();
      },
      getTableStatisticsColumns() {
        if (localStorage.getItem('tableStatisticsColumns')) {
          let data = JSON.parse(
            localStorage.getItem('tableStatisticsColumns')
          );
          if(this.filters.groupBy == 'shopwindow' ||
            this.filters.groupBy2 == 'shopwindow' ||
            this.filters.groupBy3 == 'shopwindow' ||
            this.filters.groupBy4 == 'shopwindow'
          ){
            data.shopwindow_hits = true
          }
          if(this.filters.groupBy == 'stream' ||
            this.filters.groupBy2 == 'stream' ||
            this.filters.groupBy3 == 'stream' ||
            this.filters.groupBy4 == 'stream'
          ){
            data.stream_id = true
          }
          if(this.filters.groupBy == 'country' || 
             this.filters.groupBy2 == 'country' ||
             this.filters.groupBy3 == 'country' ||
             this.filters.groupBy4 == 'country'
          ){
            data.country = true
          }
          if(this.filters.groupBy == 'offer' || 
             this.filters.groupBy2 == 'offer' ||
             this.filters.groupBy3 == 'offer' ||
             this.filters.groupBy4 == 'offer'
          ){
            data.offer = true
          }
          if(this.filters.groupBy == 'utm_campaign' || 
             this.filters.groupBy2 == 'utm_campaign' ||
             this.filters.groupBy3 == 'utm_campaign' ||
             this.filters.groupBy4 == 'utm_campaign'
          ){
            data.utm_campaign = true
          }
          if(this.filters.groupBy == 'utm_medium' || 
             this.filters.groupBy2 == 'utm_medium' ||
             this.filters.groupBy3 == 'utm_medium' ||
             this.filters.groupBy4 == 'utm_medium'
          ){
            data.utm_medium = true
          }
          if(this.filters.groupBy == 'utm_content' || 
             this.filters.groupBy2 == 'utm_content' ||
             this.filters.groupBy3 == 'utm_content' ||
             this.filters.groupBy4 == 'utm_content'
          ){
            data.utm_content = true
          }
          if(this.filters.groupBy == 'utm_term' || 
             this.filters.groupBy2 == 'utm_term' ||
             this.filters.groupBy3 == 'utm_term' ||
             this.filters.groupBy4 == 'utm_term'
          ){
            data.utm_term = true
          }

          this.tableStatisticsColumns = data;

        }
      },
      isEmptyOtherDataSession(session) {
        let valuesValidationCount = 0;

        function validationCounterObject(val) {
          if (
            val == '' ||
            val == ' ' ||
            parseInt(val) == 0 ||
            val == null ||
            val == undefined
          ) {
            valuesValidationCount = valuesValidationCount + 0;
          } else {
            valuesValidationCount = valuesValidationCount + 1;
          }
        }
        function validationCounterString(val) {
          if (
            val == '' ||
            val == ' ' ||
            parseInt(val) == 0 ||
            val == null ||
            val == undefined
          ) {
            valuesValidationCount = valuesValidationCount + 0;
          } else {
            valuesValidationCount = valuesValidationCount + 1;
          }
        }

        for (let [key, value] of Object.entries(session)) {
          if (typeof value == 'object' && value !== null) {
            if (Object.hasOwn(value, 'amount')) {
              validationCounterObject(value.amount);
            } else if (key == 'indication_of_order_buyout_by_leads') {
              validationCounterObject(value.buyout);
              validationCounterObject(value.refund);
            }
          } else {
            if (
              key != 'country' &&
              key != 'timestamp' &&
              key != 'visitors' &&
              key != 'hits' &&
              key != 'shopwindow_hits' &&
              key != 'shopwindow_type' &&
              key != 'utm_source' &&
              key != 'utm_campaign' &&
              key != 'utm_content' &&
              key != 'utm_medium' &&
              key != 'utm_term' &&
              key != 'stream_id'
            ) {
              validationCounterString(value);
            }
          }
        }

        if (
          valuesValidationCount == 0 &&
          (this.tableStatisticsColumns.hits == false ||
            this.tableStatisticsColumns.visitors == false)
        ) {
          return true;
        } else {
          return false;
        }
      },
    },
    watch: {
      filters: {
        deep: true,
        handler(val, oldVal) {
          this.getStatSession();
        },
      },
    },
    beforeMount() {
      this.getStatSession();
    },
    components: {
      StatSessionFilters,
      StatSessionChart,
    },
  };
</script>

<style lang="scss">
  @import '../../../scss/utils/mixins.scss';
  @import '../../../scss/utils/variables.scss';

  .StatSession {
    width: 100%;
    margin-top: 90px;
    @include break(md) {
      margin-top: 0;
    }
    &__table {
      margin: -30px;
      thead {
        tr {
          th {
            text-align: left;
            &.text-right {
              text-align: right;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            text-align: center;
            &.text-left {
              text-align: left;
            }
          }
        }
      }
    }
    .indicator {
      color: rgb(94, 94, 94);
      &_red {
        color: red;
      }
      &_yellow {
        color: rgb(181, 181, 0);
      }
      &_green {
        color: green;
      }
    }
  }
  .customize-table {
    width: calc(100% - 60px) !important;
    @include minWidth(720) {
      width: calc(100% - 120px) !important;
    }
    @include minWidth(1440) {
      width: 1440px !important;
    }
    &__wrapper {
      padding: 20px 0 0;
      display: grid;
      gap: 10px;
      grid-template-columns: 1fr;
      @include minWidth(720) {
        grid-template-columns: 1fr 1fr;
      }
      @include minWidth(1440) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
    &__item {
      background: gba(255, 255, 255, 1);
      &:nth-child(even) {
        background: (251, 251, 251, 1);
      }
      &_hide{
        display:none;
      }
    }
    &__button-wrapper {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: min-content;
      padding: 40px 0 20px;
      button:last-child {
        margin-left: 20px;
      }
    }
  }
</style>
