<template>
    <Card class="mb-30">
        <div class="StatSessionChart">

            <div class="StatSessionChart__head">
                <h2>Lead statistics</h2>
                <div
                    @click="expand"
                    class="StatSessionChart__expand">
                    <SvgUse  :width="22" :height="22" id="expand"></SvgUse>
                </div>
            </div>

            <div
                v-if="expanded"
                class="StatSessionChart__content">
                <canvas
                    id="wv-chart"
                    class="StatSessionChart__canvas"
                ></canvas>
            </div>



        </div>
    </Card>
</template>

<script>
    import {Chart} from 'chart.js';

    export default {
        name: 'StatSessionChart',
        props: [],
        data() {
            return {
                expanded: false,
            }
        },
        computed: {
            sessions() {
                return this.$store.state.webmaster.sessions
            },
            labels() {
                return collect(this.sessions).pluck('timestamp').all()
            },
            valid() {
                return collect(this.sessions).pluck('valid').all()
            },
            confirmed() {
                return collect(this.sessions).pluck('confirmed').all()
            },
        },
        methods: {
            makeChart() {
                const ctx = document.getElementById('wv-chart');
                if (!ctx) return;

                let wvChart = new Chart(ctx, {
                    type: 'line',
                    borderWidth: 4,
                    data: {
                        labels: this.labels,
                        datasets: [
                            {
                                label: 'Valid',
                                data: this.valid,
                                backgroundColor: [
                                    'rgba(0, 216, 86, 0.09)',
                                ],
                                borderColor: [
                                    'rgba(0, 197, 79, 1)',
                                ],
                                order: 1,
                            },
                            {
                                label: 'Confirmed',
                                data: this.confirmed,
                                backgroundColor: [
                                    'rgba(109, 175, 253, 0.09)',
                                ],
                                borderColor: [
                                    'rgba(109, 175, 253, 1)',
                                ],
                                order: 2,
                            }
                        ]
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                        }
                    }
                });

            },
            expand() {
                if (this.expanded) {
                    this.expanded = false
                } else {
                    this.expanded = true
                    setTimeout(this.makeChart, 200)
                }
            },
        },
        watch: {
            sessions: {
                deep: true,
                handler(val, oldVal) {
                    this.expanded = false

                }
            }
        },
        mounted() {
            //this.is_visible = true
            setTimeout(this.makeChart, 1000)
        }
    }
</script>

<style lang="scss">

    @import '../../../scss/utils/mixins.scss';
    @import '../../../scss/utils/variables.scss';

    .StatSessionChart{
        &__head{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        &__content{
            margin: 10px -20px 0;
            @include break(md) {
                margin: 0;
            }
        }
        &__expand{
            opacity: .5;
            transition: .2s opacity ease-in-out;
            cursor: pointer;
            &:hover{
                opacity: 1;
            }
        }
    }
</style>